import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "cnbcbajar", For eg: "newlogoname",
  const [tabs] = useState([
    "tv9gujarati",
    "abpasmita",
    "sandeshnews",
    "news18gujarati",
    "zee24kalak",
    "indianewsgujarat",
    "cnbcbajar",
  ]);

  const [selectedGujaratiTab, setSelectedGujaratiTab] = useState<string>(
    () => localStorage.getItem("selectedGujaratiTab") || "tv9gujarati"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedGujaratiTab(channelKey);
    localStorage.setItem("selectedGujaratiTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedGujaratiTab");
    if (savedTab) {
      setSelectedGujaratiTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["cnbctv18", "cnnnews18"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedGujaratiTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedGujaratiTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const tv9gujarati = `/assets/channel-logos/tv9-gujarati.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const abpasmita = `/assets/channel-logos/abp-asmita.png?v=${cacheBuster}`;
  const sandeshnews = `/assets/channel-logos/sandesh-news.png?v=${cacheBuster}`;
  const news18gujarati = `/assets/channel-logos/news18-gujarati.png?v=${cacheBuster}`;
  const zee24kalak = `/assets/channel-logos/zee-24-kalak.png?v=${cacheBuster}`;
  const indianewsgujarat = `/assets/channel-logos/india-news-gujarat.png?v=${cacheBuster}`;
  const cnbcbajar = `/assets/channel-logos/cnbc-bajar.png?v=${cacheBuster}`;

  const imagesToPreload = [
    tv9gujarati,
    abpasmita,
    sandeshnews,
    news18gujarati,
    zee24kalak,
    indianewsgujarat,
    cnbcbajar,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Gujarati News Live | TV9, ABP Asmita, News18, Zee 24 Kalak
          </title>
          <meta
            name="description"
            content="Watch live streaming of TV9 Gujarati, ABP Asmita, News18, Zee 24 Kalak & more on Gujarati-News.live. Stay updated with the latest Gujarati news from trusted channels."
          />
          <meta
            name="keywords"
            content="Gujarati news live, TV9 Gujarati live, ABP Asmita live, Sandesh News live, News18 Gujarati live, Zee 24 Kalak live, India News Gujarat live, CNBC Bajar live, Gujarati breaking news, Gujarat live news streaming, Gujarati news channels"
          />
        </Helmet>
        <Header />

        {selectedOption === "Gujarati" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 115 to 134) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("tv9gujarati") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "tv9gujarati" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("tv9gujarati")}
                  >
                    {/**** When you add new logo change the src={tv9gujarati} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9gujarati}
                        alt="TV9 Gujarati Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={tv9gujarati} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("abpasmita") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "abpasmita" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("abpasmita")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abpasmita}
                        alt="ABP Asmita Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("sandeshnews") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "sandeshnews" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("sandeshnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={sandeshnews}
                        alt="Sandesh News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18gujarati") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "news18gujarati" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("news18gujarati")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18gujarati}
                        alt="News18 Gujarati Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zee24kalak") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "zee24kalak" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("zee24kalak")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zee24kalak}
                        alt="Zee 24 Kalak Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("indianewsgujarat") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "indianewsgujarat" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("indianewsgujarat")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={indianewsgujarat}
                        alt="India News Gujarat Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("cnbcbajar") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "cnbcbajar" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("cnbcbajar")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={cnbcbajar}
                        alt="CNBC Bajar Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedGujaratiTab === "tv9gujarati" &&
                visibleTabs.includes("tv9gujarati") && (
                  <>
                    <h1>TV9 Gujarati Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/-lzRnfB2CWA?si=-CB3NfSxifIYDGl3&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Gujarati Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedGujaratiTab === "abpasmita" &&
                visibleTabs.includes("abpasmita") && (
                  <>
                    <h1>ABP Asmita Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/OF1PR4b-1Bk?si=H0qlMVtHGEeTlAlz&autoplay=1&v=${cacheBuster}`}
                      title="ABP Asmita Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "sandeshnews" &&
                visibleTabs.includes("sandeshnews") && (
                  <>
                    <h1>Sandesh News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/lnjgJk9XM2k?si=1RVYUXmmiyEMADRo&autoplay=1&v=${cacheBuster}`}
                      title="Sandesh News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "news18gujarati" &&
                visibleTabs.includes("news18gujarati") && (
                  <>
                    <h1>News18 Gujarati Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/oURmdbfbhgM?si=HacTjd99vzRw7__0&autoplay=1&v=${cacheBuster}`}
                      title="News18 Gujarati Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "zee24kalak" &&
                visibleTabs.includes("zee24kalak") && (
                  <>
                    <h1>Zee 24 Kalak Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/tm9c0c-qEXg?si=Cu-xvyAzNO6vTCev&autoplay=1&v=${cacheBuster}`}
                      title="Zee 24 Kalak Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "indianewsgujarat" &&
                visibleTabs.includes("indianewsgujarat") && (
                  <>
                    <h1>India News Gujarat Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/3iw2HKBQYlc?si=GT2lpZD1TBJh7sCB&autoplay=1&v=${cacheBuster}`}
                      title="India News Gujarat Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "cnbcbajar" &&
                visibleTabs.includes("cnbcbajar") && (
                  <>
                    <h1>CNBC Bajar Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/LziO-1JrvQo?si=8scYz5cIj35eKhNV&autoplay=1&v=${cacheBuster}`}
                      title="CNBC Bajar Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>
      <div style={{ display: "none" }}>
        <p>Welcome to Gujarati News Live – Your Hub for 24/7 Gujarati News </p>
        <p>
          Stay updated with the latest news from Gujarat and beyond at Gujarati
          News Live. Our platform provides easy access to live YouTube streams
          of the most popular Gujarati news channels, including TV9 Gujarati
          Live, ABP Asmita Live, Sandesh News Live, News18 Gujarati Live, Zee 24
          Kalak Live, India News Gujarat Live, and CNBC Bajar Live. Whether it's
          politics, business, sports, or entertainment, we bring the news to
          your fingertips, ensuring you're always informed.
        </p>

        <p>
          Comprehensive Coverage of Gujarati News Channels At Gujarati News
          Live, you’ll find a curated collection of trusted Gujarati news
          channels. TV9 Gujarati Live and ABP Asmita Live offer in-depth
          coverage of regional and national stories, while Sandesh News Live
          keeps you informed about the latest cultural and community events.
          With a single platform for all these channels, you no longer need to
          search multiple websites for updates.
        </p>

        <p>
          Real-Time News Streaming at Your Fingertips From breaking news to
          insightful debates, News18 Gujarati Live and Zee 24 Kalak Live provide
          high-quality streaming that ensures you never miss a moment. These
          channels cover critical issues that matter to Gujaratis across the
          globe, keeping you connected to your roots. Whether it's election
          results, weather updates, or exclusive interviews, you'll find it all
          here.
        </p>

        <p>
          Specialized Coverage for Business and Finance For business
          enthusiasts, CNBC Bajar Live is the go-to channel for stock market
          updates, economic trends, and financial advice. Paired with India News
          Gujarat Live, which offers a mix of news and analysis, Gujarati News
          Live becomes your ultimate destination for well-rounded updates across
          all domains.
        </p>

        <p>
          Why Choose Gujarati News Live? Our mission is to make news accessible
          and convenient for Gujarati-speaking audiences. With direct links to
          live YouTube streams of top channels, we ensure seamless access to
          trustworthy news content. Whether you are at home or on the go,
          Gujarati News Live empowers you to stay informed and connected.
          Bookmark our website today and experience the best of Gujarati
          journalism in one place!
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
